.inputBox {
  border: 1px solid #c5c5c5;
  padding: 2px 8px;
  margin: 2px 0;
  border-radius: 3px;
}
.inputBox:focus {
  outline: 2px solid #979797;
}

#projectSpaces li {
  list-style-type: none;
}

#projectSpaces ul {
  padding: 0;
  list-style: none;
  position: relative;
}

#projectSpaces .nodeList {
  position: relative;
}

#projectSpaces ul::before,
#projectSpaces li::before,
#projectSpaces ul::after,
#projectSpaces li::after,
#projectSpaces .nodeList::before,
#projectSpaces .nodeList::after {
  content: "";
  position: absolute;
  background: #979797;
}

#projectSpaces ul::before {
  height: calc(100% + 18px);
  width: 1px;
  left: -60px;
}
#projectSpaces ul::after {
  height: 1px;
  width: 15px;
  bottom: -18px;
  left: -60px;
}

#projectSpaces li::before {
  height: 1px;
  width: 15px;
  top: 50px;
  left: -20px;
}

#projectSpaces li::after {
  height: 37px;
  width: 1px;
  top: 14px;
  left: -20px;
}

#projectSpaces .nodeList::before {
  top: 14px;
  height: 1px;
  width: 55px;
  left: -60px;
}
